/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NikkeGuidesBready: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Bready Guide & Review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_bready.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Bready Guide & Review</h1>
          <h2>A guide & review for Bready in NIKKE: Goddess of Victory.</h2>
          <p>
            Last updated: <strong>30/Mar/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Bready" />
              <div className="employee-container for-nikke">
                <NikkeCharacter mode="icon" slug="bready" enablePopover />
              </div>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                Bready the Chubby Taste-Testing Cutie is a very competent unit
                against Water-weak Raids and is highly usable against Water-weak
                bosses in general. She packs quite the damage, although she will
                struggle to achieve anything remarkable in neutral elements
                compared to units like Cinderella, ScarletBS, Asuka: Wille, and
                RapiRH, who offer better overall DPS. She also requires certain
                support units to work, making her quite a picky eater, although
                you can say she's a bit versatile (she really is not) due to
                being able to work with two types of buffers and benefits from
                this exclusivity, but she has to contest her spot versus the
                common meta B3.
              </p>
              <p>
                For Water-weak bosses outside Raids specifically, her
                performance rivals other competent Water units (such as QEQ,
                xLud and HelmTr). Two bonus points for her are that she relies
                less on core compared to QEQ and XLud and her shots can be
                spammed for Burst Gen, something not achievable by the other
                two. Additionally, her Distributed Damage has proven useful for
                Boss (adds) especially against Harvester AI where you can
                continue shooting at the boss without worrying about mobs.
              </p>
              <p>
                She also works with SRosanna for her Lingering Taste route. Her
                Sustained Damage base damage is higher than her Distributed
                Damage route. However, since SRosanna is a weaker buffer in
                comparison to the Maid Duo, Bready is better used in the Maid
                team for higher damage output. In PvP, her performance is not up
                to standard and should not be considered.
              </p>
              <h5>Should I get this unit?</h5>
              <p>
                PvE-focused players and those seeking a high rank in Solo
                Raid/Union Raid should get one copy,{' '}
                <strong>but only if they have Mast + Anchor Maids</strong>. She
                will be needed for Water-weak Raids, and skipping her may
                negatively affect your score. Meanwhile, PvP-focused players can
                finally breathe a sigh of relief as this Nikke is useless.{' '}
                <strong>
                  If you do not have Mast and Anchor, Bready is the easiest skip
                  ever
                </strong>
                . She literally cannot function without them effectively.
              </p>
              <p>
                <i>Do we recommend getting dupes?</i> Generally, no. While she
                does scale well on dupes thanks to her kit having a lot of
                personal ATK buffs, we do not see any value of doing that.
                Instead, save those gems for Half-Anniversary. However, if you
                are a competitive player or a gooner, then perhaps MLB is OK.
              </p>
              <p>
                <i>Should you mileage her?</i> Absolutely not. We always
                recommend saving those tickets for high-value units such as
                Limited Collabs and Pilgrims. Don't waste those sweet tickets on
                not-that-meta units like Bready.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <h5>Skill 1</h5>
              <blockquote>
                <p>■ Activates when entering Full Burst. Affects self.</p>
                <p>ATK ▲ 70.01% for 10 sec.</p>
                <p>
                  ■ Activates when gaining the buff that increases sustained
                  damage. Affects self.
                </p>
                <p>
                  Lingering Taste: Charge Speed ▼ 20% for 50 sec. This effect
                  cannot be dispelled.
                </p>
                <p>Removes Recommended Taste.</p>
                <p>
                  ■ Activates when gaining the buff that increases distributed
                  damage while not in a sustained damage-increasing status.
                  Affects self.
                </p>
                <p>
                  Recommended Taste: Charge Speed ▼ 20% for 50 sec. This effect
                  cannot be dispelled.
                </p>
                <p>Removes Lingering Taste.</p>
              </blockquote>
              <p>
                Bready changes form depending on the type of buff she receives,
                which comes in the form of an{' '}
                <strong>un-dispellable debuff for 50s</strong> that can be
                examined under the unit's status list in combat:
              </p>
              <ul>
                <li>
                  <strong>
                    Lingering Taste (Damage Over Time aka DoT Route)
                  </strong>
                  : Activates upon receiving Sustained Damage buffs and removes
                  Recommendation Taste if it is present. In this mode, Bready's
                  Full Charge triggers Sustained Damage Effect on the target
                  every 3 shots. This can further be buffed by her Burst Skill.
                </li>
                <li>
                  <strong>Recommendation Taste (On Hit Route)</strong>:
                  Activates upon receiving Distributed Damage buff and removes
                  Lingering Taste if it is present. There also cannot be any
                  Sustained Damage buffs present to activate this buff. In this
                  mode, Bready's Full Charge attacks deal additional Distributed
                  Damage% to all enemies — which can be a useful AoE barrage
                  tool for clearing small mobs. If no mobs are present, all
                  damage will be concentrated on a single target, making her
                  viable for solo bossing.
                </li>
              </ul>
              <p>
                While any of the modes is active, Bready's Charge Speed is
                reduced by 20%, making her shoot around 0.65 shots per second
                under Auto. The time taken per shot can be reduced by cancelling
                the "into" and "out of" cover animation imposed on charging
                units without animation delay. Realistically, with proper manual
                gameplay and pre-charging, you can expect closer to 7 shots per
                Full Burst. However, her optimal gameplay may include spamming
                for burst gen with her, which makes pre-charging less
                convenient.
              </p>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> Is Charge
                  Speed Worth It?
                </h6>
                <p>
                  One question that bothers the mind of Solo Raiders is whether
                  Charge Speed is worth it for her. In short, yes, but it's not
                  better than ELE, ATK, or the first 1-2 Ammo. It is a generic
                  bonus line that works for most Chargers. However, we would
                  like to also warn you that the resultant DPS gain from each
                  Charge Speed will be lower for her due to the exponential
                  scaling of Charge Speed. This is because it functions as
                  Charge Time Reduction instead of Speed, which means the closer
                  to 100%, the stronger the Charge Speed buff becomes. At the
                  -20% default, each Charge Speed will feel 16.67% less
                  effective on her.
                </p>
              </blockquote>

              <p>
                Bready also grants herself ATK buff upon entering Full Burst for
                10s — which is nice for counteracting the effects of DEF or CP
                penalty in certain content. This also means that most of her
                damage will be inside Full Burst and not outside.
              </p>
              <h5>Skill 2</h5>
              <blockquote>
                <p>
                  ■ Activates after landing 3 Full Charge attack(s) while in
                  Lingering Taste status. Affects the target.
                </p>
                <p>Damage Taken ▲ 10.2% for 5 sec.</p>
                <p>
                  Aftertaste: Deals 150.04% of final ATK as sustained damage
                  every I sec for 5 sec.
                </p>
                <p>
                  ■ Activates when hitting a target with Full Charge while in
                  Recommended Taste status. Affects self.
                </p>
                <p>Attack Damage ▲ 60.01% for 5 sec.</p>
                <p>
                  ■ Activates when hitting a target with Full Charge while in
                  Recommended Taste status. Affects all enemies.
                </p>
                <p>Deals 265.07% of final ATK as distributed damage.</p>
              </blockquote>
              <p>
                Her Lingering Taste - DoT Route has a higher base damage output
                even after the extreme nerf. However, the lack of compatible
                support units makes it not worth running this Route now. This
                might be compensated by stronger Sustained DMG buffers in the
                future — for now, SRosanna just doesn't cut it.
              </p>
              <p>
                Hence, there is practically no reason for running the DoT Route,
                and Distributed Damage also has one hidden advantage —
                Distributed Damage buffs are calculated in the Damage Taken
                group, whereas Sustained Damage aren't. And guess what, she
                already has a lot of ATK DMG!
              </p>
              <p>
                However, the DoT Route also has one benefit that the On Hit one
                doesn't have — Damage Taken — which can improve the team's
                overall damage. It's more of a support skill. ~10% is small, but
                it's tangible.
              </p>
              <p>
                In the On Hit mode, Bready's fully charged attacks also grant
                her 60.01% ATK DMG, which is big. That on top of the nice 265%
                multiplier + basic attack offer good single-target damage and
                AoE potential.
              </p>
              <h5>Burst Skill</h5>
              <blockquote>
                <p>■ Affects self.</p>
                <p>Attack Damage ▲ 60.19% for 10 sec.</p>
                <p>■ Activates when in Lingering Taste status. Affects self.</p>
                <p>Aftertaste Effect ▲ 349.8% for 10 sec.</p>
                <p>
                  ■ Activates when in Recommended Taste status. Affects self.
                </p>
                <p>ATK ▲ 70.09% for 10 sec.</p>
              </blockquote>
              <p>
                Instant huge ATK DMG that boosts her damage significantly for
                10s. During this 10s, you will want Bready to deal as much
                damage as possible, increasing the value of{' '}
                <strong>pre-charging</strong> and{' '}
                <strong>manually controlling her</strong>.
              </p>
              <p>
                If she is in the <strong>Lingering Taste (DoT Route)</strong>{' '}
                status, her Sustained DMG multiplier will be increased by up to
                349.8% (at max level). This means that she will be dealing
                449.8% of 150.04% (at max level), which is around 674.87%, of
                Sustained DMG per second. This value is fairly small but bigger
                than most Burst Skills from old units.
              </p>
              <p>
                In <strong>Recommended Taste (On hit Route)</strong> status,
                instead of gaining additional multipliers, Bready gains an ATK
                boost that gives her an edge in situations where enemy DEF stat
                is higher than usual. This feels less interesting, but the
                damage spike is noticeable, and you should try to dish out as
                much damage as possible during Burst. However, now that she has
                a lot of ATK, she does not scale too well from external ATK
                buffs temporarily.
              </p>
              <h5>Synergies</h5>
              <p>
                <strong>Bready pairs well with the following units: </strong>{' '}
              </p>

              <div className="employee-container for-nikke">
                <NikkeCharacter
                  mode="icon"
                  slug="mast-romantic-maid"
                  enablePopover
                />
                <NikkeCharacter
                  mode="icon"
                  slug="anchor-innocent-maid"
                  enablePopover
                />
              </div>
              <p>
                Indubitably the top pairs for now until another decent buffer,
                probably in the Sustained Damage domain, is released for Bready.
                Her skill requires receiving a certain type of buff to activate.
                Without them, she is pretty much a blank slate.
              </p>
              <div className="employee-container for-nikke">
                <NikkeCharacter
                  mode="icon"
                  slug="rosanna-chic-ocean"
                  enablePopover
                />
              </div>
              <p>
                The second best pair, offering Sustained DMG▲, but with the nerf
                almost halfing her Sustained DMG route, there is no motivation
                to play her in place of better B2 units.
              </p>
              <div className="employee-container for-nikke">
                <NikkeCharacter
                  mode="icon"
                  slug="rapi-red-hood"
                  enablePopover
                />
                <NikkeCharacter mode="icon" slug="crown" enablePopover />
                <NikkeCharacter
                  mode="icon"
                  slug="quency-escape-queen"
                  enablePopover
                />
                <NikkeCharacter
                  mode="icon"
                  slug="ludmilla-winter-owner"
                  enablePopover
                />
                <NikkeCharacter
                  mode="icon"
                  slug="helm-treasure"
                  enablePopover
                />
              </div>
              <p>
                You will see her being paired a lot with these units. Firstly,
                QQ, XLud, and HelmTr are fellow Water DPS units, so you might
                see them going together for Water-weak bossing. Bready also
                offers Burst Gen Support via shots spamming, which complement
                slower units like QQ and XLud well.
              </p>
              <p>
                Outside Raid, it's possible that two Maids won’t be run at the
                same time and instead one Maid will be used with Crown for
                increased overall team damage. Lastly, Rapipi is a widely
                effective generic B1 CDR.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Usage Analysis and Tier List" />
              <h5>
                Campaign (LD) - <strong className="b">B</strong> | Campaign (HD)
                - <strong className="b">B</strong>
              </h5>
              <p>
                Bready has AoE damage and clusters of mobs spawning at once in
                Campaign Stages can easily overwhelm her. However, in lower
                deficits or against Water Bosses in Campaign which require
                Distributed Damage such as Black Snake, she might just be one of
                the better options to use.
              </p>
              <p>
                The disadvantage of using her and why she is not in A tier or
                above is due to the fact that she does not work with Crown
                alone, and needs Maid Mast or Maid Anchor to work. This
                specificity means she is not an ideal choice for Campaign,
                especially for heavy mobbing. Nevertheless, she can offer some
                minor Burst Gen Support and decent single-target damage through
                basic attacks. This is because she has a lot of ATK buffs that
                negate stat penalty. However, you will need to deploy either the
                Maids or SRosanna with her, which is not always ideal. An
                alternative unit for Water-weak Campaign.
              </p>
              <h5>
                Bossing - <strong className="s">S</strong>
              </h5>
              <p>
                Very good single-target bossing damage (equal to QEQ or slightly
                better/worse depending on whether the enemy has core, and also
                varies depending on how many ELE rolls you have on either of
                them) with some AoE support potential. Her damage is enough to
                make one of the best Water DPS units at the moment, not to
                mention the added bonus burst gen capability. She will
                definitely have a place in Water-weak Raids and can be used
                outside for Water-weak bosses (esp. Black Snake in Campaign &
                Harvester AI).
              </p>
              <p>
                Future semi-support potential if they release a broken Sustained
                Damage Water DPS unit at the level of Cinderella (that doesn't
                have burst gen). Pairing her with Bready for burst gen + Damage
                Taken can be a good idea.
              </p>
              <h5>
                PVP - <strong className="e">E</strong>
              </h5>
              <p>
                Not only do you need certain buffers to activate her skillset,
                her damage is also too low to matter. Can be used as a normal SR
                to generate some mediocre Burst Generation. Long story short, a
                Sniper Rifle without any skill in PVP. The only reason we put
                her here instead of F is because she has higher base stat than R
                and SR units.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <p>
                OL is required because she is a DPS and you want offensive
                lines, like ELE & ATK. Aim for 1~2× Max Ammo for Burst Gen,
                ideally two, but you can run her without any Max Ammo
                (especially with Maids since they give Reload Speed, but you
                still need at least one in our opinion). Other lines are up to
                your preference, but Charge Speed, CRIT Rate, and CRIT DMG are
                decent. CRIT synergizes well with Maids' buffs.
              </p>
              <ul>
                <li>
                  <strong>Essential:</strong> -
                </li>
                <li>
                  <strong>Ideal:</strong> 4× ELE, 4× ATK, 1× Max Ammo
                </li>
                <li>
                  <strong>Passable:</strong> 1× Max Ammo, 2-3× Charge Speed/CRIT
                  Rate/CRIT DMG
                </li>
                <li>
                  <strong>Priority:</strong> High (PvE)
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <p>Bready expects balanced skill investments:</p>
              <ul>
                <li>
                  <strong>Skill 1: 7~10</strong>
                </li>
                <ul>
                  <li> More ATK for more DMG.</li>
                </ul>
                <li>
                  <strong>Skill 2: 7~10</strong>
                </li>
                <ul>
                  <li>Higher multiplier for DMG%.</li>
                  <li>
                    Prioritize especially for the On Hit route because this is
                    the only skill that affects its base multiplier. It also
                    gives a high ATK DMG buff.
                  </li>
                  <li>
                    Prioritize when using her as a semi-support (in the future,
                    if they release a broken Water DPS unit at the level of
                    Cinderella, that Damage Taken▲ will prove reliable).
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill:4/7/10</strong>
                </li>
                <ul>
                  <li>Huge ATK DMG but temporary.</li>
                  <li>
                    Increased multiplier for DoT route, making this more
                    important for it, around +45% final damage from level 1 to
                    10 for the sustained damage itself.
                  </li>
                  <li>
                    Still less value than S2 for DoT route, but their
                    relationship is multiplicative, so upgrade both at the same
                    time.
                  </li>
                  <li>Increased damage for On Hit route.</li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="bready" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_3_sm.png"
                alt="Guide"
              />
              <p>
                Resilience always when pairing with Maids as the relationship
                between Reload Speed and Overall Uptime is exponential.
              </p>
              <h5>Doll Investments</h5>
              <p>
                We feel SR 5 is justifiable for her. A standard bossing Nikke
                for a specific element. SR 15 only if you are serious about
                Water Raids, but it’s not recommended for 99% of players due to
                its EXP cost. There are better units to SR 15.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: Maid-for-Bready</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="placeholder-box">B1CDR</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="mast-romantic-maid"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="bready" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="anchor-innocent-maid"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                The best team for Bready and probably what will be used in
                Water-weak Raids. This activates her On Hit route, which allows
                her to perform at a highly competitive level.
              </p>
              <h5>Team #2: Sustain This Damage! </h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="placeholder-box">B1CDR</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rosanna-chic-ocean"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="bready" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="helm-treasure"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
              </div>
              <p>
                This is the only team possible that activates her DoT Route. It
                is decent but will be weaker than the Maid Duo. We can replace
                S.Rosanna with better DoT Buffer when the time comes.
              </p>
              <h5>Team #3: Harvester is an Anomaly</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="helm-treasure"
                      enablePopover
                    />
                    <NikkeCharacter
                      mode="icon"
                      slug="ludmilla-winter-owner"
                      enablePopover
                    />
                    <NikkeCharacter
                      mode="icon"
                      slug="quency-escape-queen"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="bready" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="mast-romantic-maid"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                The new generic Water-weak bossing team. Harvester has mobs, and
                Bready has distributed damage to take care of them. This allows
                your other Water DPS units to freely shoot the boss. This team
                can be used to full auto Stage 7 (except for the QTE), given
                that the other B3 you use is Helm-Treasure for burst generation.
              </p>
              <h5>Team #4: Snake Writes about Snake’s Teams</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="anchor-innocent-maid"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="bready" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="helm-treasure"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                The fight against Black Snake in campaign (specifically
                campaign) is a wall every one of us will have to face. This
                team, for now, will probably be the best fit to fight against it
                and clear at the highest deficit possible, displacing SBS from
                her spot.
              </p>
              <h5>Team #5: Let Me Copegg</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="placeholder-box">B1CDR</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="elegg" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="bready" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
              </div>
              <p>
                A cope team for Bready, but it does work. If you are a fan of
                chubby Nikkes, this team is for you! You can even add similar
                waifus like Anis here, with Biscuit in the FLE, spot to buff
                Anis and Elegg! Adding SBS or QeQ in the other B3 spot is also a
                viable option. Keep in mind, however, that this is one of the
                weaker teams for Bready.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="PvP Team Compositions" />
              <p>Just don't.</p>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Strong Water-weak damage dealer for bosses.</li>
                        <li>
                          Has two modes — can work with two different types of
                          damage buffers.
                        </li>
                        <li>
                          Has some AoE clearing potential that helps against
                          fights against certain Boss (adds) like Harvester AI.
                        </li>
                        <li>
                          Lots of personal buffs; good to negate stat penalty in
                          high deficits.
                        </li>
                        <li>Damage Taken Debuff helps the team.</li>
                        <li>Black Snake killer.</li>
                        <li>Spicy Bond Eps.</li>
                        <li>Saucy Blabla chats.</li>
                        <li>Salacious illustrations.</li>
                        <li>
                          Cute lil chubster that everyone wants to pinch. Shift
                          Up really cooked with Bready!
                        </li>
                        <li>No, I’ll use her as a pillow. Thanks. - Kisenix</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Minimal impact in PvP.</li>
                        <li>
                          Skills don’t activate without certain buffs from
                          teammates. Stuck with Maids (or S.Rosanna) for now.
                        </li>
                        <li>
                          The number of Sustained DMG ▲ buffers is low (actually
                          we only have one), and most (actually all) are out of
                          meta.
                        </li>
                        <li>
                          A lot of self ATK during her Burst in Recommended
                          Taste mode dilutes the ATK buff pool.
                        </li>
                        <li>
                          Mob clearing potential exists but is weak since it is
                          distributed damage.
                        </li>
                        <li>
                          Not sure if it's worth building right now unless you
                          are competitive, since Collab squeezed most players
                          dry and Half Anniv is just one month away.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-5">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesBready;

export const Head: React.FC = () => (
  <Seo
    title="Bready Guide & Review | NIKKE | Prydwen Institute"
    description="A guide & review for Bready in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
